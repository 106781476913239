// external imports
import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'
import Select from 'react-select'
import Geocode from 'react-geocode'

// helpers
import { up, addResponsivity } from '../lib/styles'
import { extractNodes, extractNodesFromData } from '../lib/helpers'
import injectIntl from '../lib/intl/injectIntl'
import withExtraction from '../lib/withExtraction'

// components
import SEO from '../components/SEO'
import {
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Button,
  Line,
  HeaderBar,
} from '../components'
import { Page, PageContent } from '../components/Page'
import Map from '../components/Map'
import { getCollaboratorsKeys, collaborators } from '../collaborators'
import { StyledInput } from '../components/fields/Input'
import headerBarImageSrc from '../data/images/collaborators-top.png'
import T from '../lib/intl/T'
import { isSk } from '../lib/appVersions'
import IntlProvider from '../lib/intl/IntlProvider'

const collaboratorKeys = getCollaboratorsKeys()

Geocode.setApiKey('AIzaSyBYNaAhmMB388gVaj70mvJIRPi6vcu7jsE')
Geocode.setLanguage('cs')
Geocode.setRegion('cz')

// tabs for select
const options = collaboratorKeys.map(key => ({
  value: key,
  label: <T>{collaborators[key].label}</T>,
}))

const Wrapper = styled.div`
  ${({ theme: { colors } }) => css`
    background-color: #ffffff;
    padding: 0 24px;
    box-shadow: 6px 10px 81px rgba(0, 0, 0, 0.1);

    ${up('mobile')} {
      padding: 0 64px;
    }
  `}
`

class Collaborators extends React.Component {
  constructor(props) {
    super()
    this.state = {
      activeFilterKey: '',
      placeFilterText: '',
      mapFilteredItems: [],
      maxItems: 10,
      placeLocation: {
        lat: null,
        lng: null,
      },
      mapZoom: 7,
    }
  }

  mapRef = null

  componentDidMount() {}

  render() {
    const {
      activeFilterKey,
      placeFilterText,
      mapFilteredItems,
      maxItems,
      placeLocation,
      mapZoom,
    } = this.state
    const {
      data: { allCollaborators },
      intl: { t, headless, language },
    } = this.props

    // console.log('allCollaborators', allCollaborators)

    const mapItems = allCollaborators.filter(
      item => item.type === activeFilterKey || !activeFilterKey
    )

    const mapItemsForFiltering = [...allCollaborators]

    const filteredItems = mapFilteredItems.filter(
      item => item.type === activeFilterKey || !activeFilterKey
    )

    const itemsToList = filteredItems.slice(0, maxItems)

    return (
      <>
        <SEO title={t('Lektoři a školy')} />

        <Page grey>
          <PageContent>
            <Gap gap="90px" mobileGap="40px" />

            <Wrapper>
              <Col alignItems="center">
                <Gap gap="90px" mobileGap="40px" />
                <Title.Section textAlign="center">
                  <T>Rychlé hledání FIE/LPAD škol či lektorů</T>
                </Title.Section>
                <Gap gap="40px" mobileGap="32px" />

                <Row
                  justifyContent="space-between"
                  responsive
                  width="100%"
                  maxWidth="1020px"
                >
                  <Col width="25%" mobileWidth="auto">
                    <Select
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                      })}
                      styles={{
                        control: base => ({
                          ...base,
                          height: 51,
                          minHeight: 51,
                        }),
                      }}
                      options={options}
                      value={options.find(opt => opt.value === activeFilterKey)}
                      isSearchable={false}
                      isClearable
                      placeholder={<T>Vše</T>}
                      onChange={(opt, { action } = {}) => {
                        // console.log('action', action, opt)
                        if (action === 'select-option') {
                          this.setState({
                            activeFilterKey: opt && opt.value,
                          })
                        }
                        if (action === 'clear') {
                          this.setState({ activeFilterKey: null })
                        }
                      }}
                    />
                  </Col>

                  <Gap gap="20px" />

                  <Col width="50%" grow="2" mobileWidth="auto">
                    <StyledInput
                      placeholder={t('NAPIŠTE LOKALITU')}
                      value={placeFilterText}
                      lineHeight="48px"
                      placeholderFontWeight={400}
                      onChange={e => {
                        this.setState({ placeFilterText: e.target.value })
                      }}
                    />
                  </Col>

                  <Gap gap="20px" />

                  <Col>
                    <Button.PrimaryOrange
                      iconLeft="search"
                      onClick={() => {
                        if (!placeFilterText) return
                        // Get latidude & longitude from address.
                        Geocode.fromAddress(placeFilterText).then(
                          response => {
                            const {
                              lat,
                              lng,
                            } = response.results[0].geometry.location

                            this.setState({
                              placeLocation: { lat, lng },
                              mapZoom: 12,
                            })
                            this.mapRef.panTo({ lat, lng })
                            Link.scrollTo(Link.anchors.COLLABORATORS_MAP)
                          },
                          error => {
                            console.error(error)
                          }
                        )
                      }}
                    >
                      <T>najít</T>
                    </Button.PrimaryOrange>
                  </Col>
                </Row>

                <Gap gap="108px" mobileGap="40px" />
              </Col>
            </Wrapper>

            <Gap gap="86px" />
            <Wrapper>
              <Col>
                <div id={Link.anchors.COLLABORATORS_MAP} />
                <Gap gap="60px" />
                <Map
                  zoom={mapZoom}
                  onRefUpdate={ref => {
                    this.mapRef = ref
                  }}
                  markerLocation={placeLocation}
                  items={mapItems}
                  itemsToFilter={mapItemsForFiltering}
                  onFilteredItemsChange={mapFilteredItems => {
                    this.setState({
                      mapFilteredItems,
                    })
                  }}
                />
                <Gap gap="68px" />
                {/* <Title.Section textAlign="center">
                  Seznam{' '}
                  {activeFilterKey
                    ? collaborators[activeFilterKey].labelTitle
                    : 'všech položek'}
                </Title.Section>
                <Gap gap="100px" /> */}
                {itemsToList.map(col => (
                  <>
                    <Col>
                      <Gap gap="30px" />
                      <Row
                        justifyContent="space-between"
                        alignItems="center"
                        responsive
                      >
                        <Text fontWeight="600">{col.name}</Text>
                        {col.link && (
                          <>
                            <Gap gap="20px" mobile />

                            <Button.SecondaryOrange
                              iconLeft="web"
                              link={{ asA: true, href: col.link }}
                            >
                              <T>přejít na web</T>
                            </Button.SecondaryOrange>
                          </>
                        )}
                        {!col.link && col.email && (
                          <>
                            <Gap gap="20px" mobile />

                            <Button.SecondaryOrange
                              iconLeft="email"
                              link={{
                                asA: true,
                                href: `mailto:${col.email}`,
                              }}
                            >
                              {col.email}
                            </Button.SecondaryOrange>
                          </>
                        )}
                      </Row>
                      <Gap gap="30px" />
                      <Line />
                    </Col>
                  </>
                ))}
                {(!itemsToList || !itemsToList.length) && (
                  <Text textAlign="center">
                    <T>
                      Nic nenalezeno. Zkuste oddálit mapu a změnit filtrování
                      dle typu výše.
                    </T>
                  </Text>
                )}
              </Col>

              {maxItems < filteredItems.length && (
                <>
                  <Gap gap="60px" />
                  <Col alignSelf="center" alignItems="center">
                    <Button.PrimaryOrange
                      iconLeft="arrowDown"
                      onClick={() => {
                        this.setState({ maxItems: maxItems + 10 })
                      }}
                    >
                      <T>načíst další</T>
                    </Button.PrimaryOrange>
                  </Col>
                </>
              )}
              <Gap gap="86px" />
            </Wrapper>
            <Gap gap="95px" />
          </PageContent>
        </Page>
      </>
    )
  }
}

export default injectIntl(withExtraction(Collaborators))

export const pageQuery = graphql`
  query {
    ks {
      allCollaborators(where: { isPublished: true }, sortBy: sortOrder_ASC) {
        ...CollaboratorFragment
      }
    }
  }
`
